<template>
  <div class="page-quizzes-create">
    <div class="columns is-gapless is-vh">
      <div class="column is-2 has-background-dark">
        <Sidebar/>
      </div>

      <div class="column">
        <div class="section">
          <div class="buttons">
            <b-button tag="router-link"
              :to="'/quizzes'"
              type="is-primary"
              icon-left="chevron-left">
              Back to Overview
            </b-button>
          </div>

          <h1 class="title">Create new quiz</h1>
          <hr>

          <QuizCreateForm :afterRedirect="afterRedirect" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import QuizCreateForm from '@/components/forms/QuizCreateForm.vue'

export default {
  name: 'QuizzesUpdate',
  components: {
    Sidebar,
    QuizCreateForm,
  },
  data: function() {
    return {
      afterRedirect: {
        name: 'quizzes',
      },
    };
  },
}
</script>
